// ** Initial State
const initialState = {
  rules: {},
  categories: [],
  advertisers_profits_rules: [],
  influencers_profits_rules: [],
  tax: 0,
  general_data: {
    show_coupons: true,
    show_social_medias: true,
    show_categories: true,
  },
  versions: {
    company_android_version: '',
    company_ios_version: '',
    influencers_android_version: '',
    influencers_ios_version: '',
  },
};

const RulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RULES":
      return {
        ...state,
        ...action
      };
    case "CATEGORIES":
      return {
        ...state,
        ...action
      };
      case "ADD_CATEGORIES":
        let categoriesCopy = [...state.categories];
        categoriesCopy.unshift(action.categories)
  
        return {
  
          ...state,
          categories: categoriesCopy
        };
      case "UPDATE_CATEGORIES":
        
        const categoryIndex = state.categories.find((s) => s._id == action.categories._id);
        if (categoryIndex) {
          Object.assign(categoryIndex, action.categories);
        }
        
  
        return {
  
          ...state,
          categories: [...state.categories]
        };
    default:
      return state;
  }
};

export default RulesReducer;
