// ** Initial State
const initialState = {
  data: [],
  total: 0,
  details:{},
  projects:{},
  stats:{},
};

const AdvertiserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADVERTISERS":
      return {
        ...state,
        ...action
      };
    case "ADVERTISER_PROJECTS":

      return {

        ...state,
       ...action
      };
    case "ADVERTISER_DETAILS":
     
      return {

        ...state,
        ...action
      };
    
    default:
      return state;
  }
};

export default AdvertiserReducer;
