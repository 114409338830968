// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import {generalReducer,generalNotificationsReducer} from './general'
import dataTables from '@src/views/manage-users/store/reducer'
import requests from '@src/views/requests/store/reducer'
import ads from '@src/views/ads/store/reducer'
import settings from '@src/views/settings-page/store/reducer'
import landing from '@src/views/landing-page/store/reducer'
import chat from '@src/views/chat/store/reducer'
import suggestions from '@src/views/suggestions/store/reducer'
import advertisingCoupons from '@src/views/coupons/store/reducer'
import finance   from '@src/views/finance/store/reducer'
import influencers   from '@src/views/influencers/store/reducer'
import advertisers   from '@src/views/advertisers/store/reducer'
import projects   from '@src/views/projects-management/store/reducer'



const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  chat,
  suggestions,
  advertisingCoupons,
  landing,
  dataTables,
  general:generalReducer,
  notifications:generalNotificationsReducer,
  requests,
  ads,
  settings,
  finance,
  influencers,
  advertisers,
  projects
})

export default rootReducer
