// ** Initial State
const initialState = {
  data: [],
  total: 1,
  stats: [],
  financeType:1

}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FINANCE_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        financeType: action.financeType,
      }
      case "GET_FINANCE_STAT":
        return {
          ...state,
          ...action
        };
    default:
      return state
  }
}

export default DataTablesReducer
