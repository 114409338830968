// ** Initial State
const initialState = {
  faqs: [],
  contactMessages: [],
  contactMessageDetails: null,
};

const LandingReducer = (state = initialState, action) => {
  switch (action.type) {
    

    case "FAQS":
      return {
        ...state,
        ...action
      };
    case "ADD_FAQS":
      let faqsCopy = [...state.faqs];
      faqsCopy.unshift(action.data)

      return {

        ...state,
        faqs: faqsCopy
      };
    case "UPDATE_FAQS":

      const articleIndex = state.faqs.find((s) => s._id == action.data._id);
      if (articleIndex) {
        Object.assign(articleIndex, action.data);
      }


      return {

        ...state,
        faqs: [...state.faqs]
      };
    case "DELETE_FAQS":
      let _faqs = [...state.faqs];
      let _articleIndex = _faqs.findIndex((article) => article._id === action.id);
      _faqs.splice(_articleIndex, 1)
      return {

        ...state,
        faqs: _faqs
      };
    case "GET_CONTACT_MESSAGES":
      return {
        ...state,
        contactMessages: action.data.list,

      };
    case "GET_ONE_CONTACT_MESSAGE":
      return { ...state, contactMessageDetails: action.data.data };
    default:
      return state;
  }
};

export default LandingReducer;
