// ** Initial State
const initialState = {
  data: [],
  total: 0
};

const AdvertisingCouponsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADVERTISING_COUPONS":
      return {
        ...state,
        ...action
      };
  
    case "UPDATE_ADVERTISING_COUPONS":
      
      const articleIndex = state.data.find((s) => s._id == action.data._id);
      if (articleIndex) {
        Object.assign(articleIndex, action.data);
      }
      

      return {

        ...state,
        data: [...state.data]
      };
    default:
      return state;
  }
};

export default AdvertisingCouponsReducer;
