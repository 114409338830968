// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  project: {},
  users:[]
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'PROJECT_DETAILS':
      return {
        ...state,
        ...action
      }
    case 'SET_USERS':
      return {
        ...state,
        ...action
      }
      
    case "UPDATE_PROJECT":
      const projectIndex = state.data.find((s) => s._id == action.data._id);
      if (projectIndex) {
        Object.assign(projectIndex, action.data);
      }



      return {

        ...state,
        data: [...state.data],
        project: { ...action.data }

      };
    default:
      return state
  }
}

export default DataTablesReducer
