// ** Initial State
const initialState = {
  data: [],
  total: 0
};

const AdsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADS":
      return {
        ...state,
        ...action
      };
    case "ADD_ADS":
      let articlesCopy = [...state.data];
      articlesCopy.unshift(action.data)

      return {

        ...state,
        data: articlesCopy
      };
    case "UPDATE_ADS":
      
      const articleIndex = state.data.find((s) => s._id == action.data._id);
      if (articleIndex) {
        Object.assign(articleIndex, action.data);
      }
      

      return {

        ...state,
        data: [...state.data]
      };
    case "DELETE_ADS":
      let _articles = [...state.data];
      let _articleIndex = _articles.findIndex((article) => article._id === action.id);
      _articles.splice(_articleIndex, 1)
      return {

        ...state,
        data: _articles
      };
    default:
      return state;
  }
};

export default AdsReducer;
